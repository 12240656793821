import { dev } from "$app/environment";
import * as Sentry from '@sentry/sveltekit';

if (!dev) {
  Sentry.init({
    dsn: "https://646e7439e89448318ac21595a64819f1@o498649.ingest.us.sentry.io/5576391",
    tracesSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

// export function handleError({ event, error }) {
// 	console.error(error.stack);

// 	return {
// 		message: 'everything is fine',
// 		code: 'JEREMYBEARIMY'
// 	};
// }